import "./Auth.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../Authentication/AthenticationSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isAuthenticated,
  } = useSelector((store) => store.authentication);

  const [LoginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isSuccess && isAuthenticated) {
      navigate("/");
    }
  }, [isSuccess, isAuthenticated]);

  useEffect(() => {
    document.querySelector('input[name="email"]').focus();
  }, []);

  return (
    <div className="bg-primary h-100 d-flex align-items-center ">
      <div className="w-100 d-flex justify-content-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(login(LoginForm));
          }}
          className="auth-form w-100 bg-secondary"
        >
          <div className="text-center">
            <h3 style={{ paddingBottom: "2rem" }}>
              Please login with your credentials
            </h3>
            <p
              style={{ paddingBottom: "1rem" }}
              className="fw-medium text-danger"
            >
              {message}
            </p>
          </div>
          <div className="form-group">
            <input
              value={LoginForm.email}
              onChange={(e) => {
                setLoginForm((prev) => {
                  return { ...prev, email: e.target.value };
                });
              }}
              name="email"
              placeholder="Email"
              data-type="text"
            />
            {formErrors?.email && (
              <span className="text-danger input-error">
                {formErrors?.email}
              </span>
            )}
          </div>
          <div className="form-group">
            <input
              value={LoginForm.password}
              onChange={(e) => {
                setLoginForm((prev) => {
                  return { ...prev, password: e.target.value };
                });
              }}
              data-type="text"
              type="password"
              placeholder="Password"
            />
            {formErrors?.password && (
              <span className="text-danger input-error">
                {formErrors?.password}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary btn-lg">
              <h3>Login</h3>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

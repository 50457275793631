import { BrowserRouter, Routes, Route } from "react-router-dom";

import Contacts from "./features/contacts/Contacts";
import ContactTypes from "./features/contacts/ContactTypes";
import Settings from "./features/Settings/Settings";
import Error from "./layouts/error/Error";
import Main from "./layouts/main/Main";

import { store } from "./store";
import { Provider } from "react-redux";
import ContactFields from "./features/contacts/ContactFields";
import Roles from "./features/roles/Roles";
import Users from "./features/users/Users";
import Login from "./features/Authentication/Login";
import Projects from "./features/projects/Projects";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Dashboard from "./features/dashboard/Dashboard";
import TeasingContacts from "./features/contacts/TeasingContacts";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Main />}>
              <Route index element={<Dashboard />} />

              <Route path="ted-users" element={<Users />} />
              <Route path="ted-roles" element={<Roles />} />

              <Route
                path="ted-teasing-contacts"
                element={<TeasingContacts />}
              />

              <Route path="ted-contacts">
                <Route index element={<Contacts />} />
                <Route path="contact-types" element={<ContactTypes />} />
                <Route path="contact-fields" element={<ContactFields />} />
              </Route>

              <Route path="ted-projects/:state" element={<Projects />} />

              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<Error code={404} />} />
              <Route path="403" element={<Error code={403} />} />
            </Route>
          </Routes>
        </LocalizationProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchUsers = createAsyncThunk(
  "users/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchUserById = createAsyncThunk(
  "users/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/users/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createUser = createAsyncThunk(
  "users/create",
  (payload, { dispatch }) => {
    return api
      .post("/users", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  (payload, { dispatch }) => {
    return api
      .put("/users/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/users/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const blockUser = createAsyncThunk(
  "users/block",
  (payload, { dispatch }) => {
    return api
      .get("/users/" + payload + "/block/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  users: null,
  user: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Users
    [fetchUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.users = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchUsers.rejected]: (state) => {},

    // Fetch User By ID
    [fetchUserById.pending]: (state) => {},
    [fetchUserById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.user = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchUserById.rejected]: (state) => {},

    // Create New User
    [createUser.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.users[state.users.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createUser.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update User
    [updateUser.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.users.indexOf(
          state.users.find((user) => user._id === payload.data._id)
        );
        state.users = [
          ...state.users.slice(0, index),
          payload.data,
          ...state.users.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateUser.rejected]: (state) => {},

    // Delete User
    [deleteUser.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.users = state.users.filter(
          (user) => user._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteUser.rejected]: (state) => {},

    // Block/Unblock User
    [blockUser.pending]: (state) => {},
    [blockUser.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        let index = state.users.indexOf(
          state.users.find((user) => user._id === payload.data.id)
        );
        state.users = [
          ...state.users.slice(0, index),
          {
            ...state.users[index],
            blocked: payload.data.blocked,
          },
          ...state.users.slice(index + 1),
        ];
        state.message = payload.message;
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [blockUser.rejected]: (state) => {},
  },
});

export const { reset } = UsersSlice.actions;

export default UsersSlice.reducer;

import "./Navbar.css";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faBarsStaggered,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

function Navbar({
  toggleSidebar,
  toggleSidebarExpanded,
  sidebarOpen,
  sidebarRight,
  openSidebar,
}) {
  return (
    <div
      id="navbar"
      className={`${sidebarRight ? "sidebar-right" : ""} ${
        !sidebarOpen ? "sidebar-closed" : ""
      }`}
    >
      <div>
        <button
          className="sidebar-btn btn-primary btn icon-btn toggle-sidebar-expanded"
          onMouseEnter={() => {
            if (!sidebarOpen) {
              toggleSidebarExpanded();
            }
          }}
          onClick={() => {
            toggleSidebar();
            toggleSidebarExpanded();
          }}
        >
          <FontAwesomeIcon
            icon={
              sidebarRight
                ? sidebarOpen
                  ? faAnglesRight
                  : faBarsStaggered
                : sidebarOpen
                ? faAnglesLeft
                : faBarsStaggered
            }
          />
        </button>
        <button
          className="sidebar-btn btn-primary btn icon-btn toggle-sidebar-open"
          onClick={openSidebar}
        >
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>
      <div className="w-100">
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            isActive
              ? "active settings ml-auto btn icon-btn btn-primary"
              : "settings ml-auto btn icon-btn btn-primary"
          }
        >
          <FontAwesomeIcon icon={faGear} />
        </NavLink>
      </div>
    </div>
  );
}

export default Navbar;

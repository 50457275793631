import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchRoles = createAsyncThunk(
  "roles/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/roles", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchRoleById = createAsyncThunk(
  "roles/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/roles/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createRole = createAsyncThunk(
  "roles/create",
  (payload, { dispatch }) => {
    return api
      .post("/roles", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateRole = createAsyncThunk(
  "roles/update",
  (payload, { dispatch }) => {
    return api
      .put("/roles/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteRole = createAsyncThunk(
  "roles/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/roles/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  roles: null,
  role: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    reset: (state) => {
      state.role = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Roles
    [fetchRoles.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchRoles.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.roles = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchRoles.rejected]: (state) => {},

    // Fetch Role By ID
    [fetchRoleById.pending]: (state) => {},
    [fetchRoleById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.role = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchRoleById.rejected]: (state) => {},

    // Create New Role
    [createRole.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createRole.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.roles[state.roles.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createRole.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update Role
    [updateRole.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updateRole.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.roles.indexOf(
          state.roles.find((role) => role._id === payload.data._id)
        );
        state.roles = [
          ...state.roles.slice(0, index),
          payload.data,
          ...state.roles.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateRole.rejected]: (state) => {},

    // Delete Role
    [deleteRole.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteRole.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.roles = state.roles.filter(
          (role) => role._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteRole.rejected]: (state) => {},
  },
});

export const { reset } = RolesSlice.actions;

export default RolesSlice.reducer;

import { Link } from "react-router-dom";

function Error({ code }) {
  return <div className="h-100 d-flex flex-column align-items-center justify-content-center">
    <h1 className="text-highlight text-bold form-group">{code}</h1>
    <h3 className="fw-semibold text-secondary form-group">{code === 404 && 'Resource not found' || code === 403 && 'You are allowed to access this resource'}</h3>
    <Link to="/" style={{ marginBottom: '40vh' }} className="btn btn-primary btn-lg no-decoration">
      Back to dashboard
    </Link>
  </div>;
}

export default Error;

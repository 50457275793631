import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchMainNumbers = createAsyncThunk(
  "dashboard/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/dashboard", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  mainNumbers: null,
  message: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: {
    // Fetch All Projects
    [fetchMainNumbers.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchMainNumbers.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.mainNumbers = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchMainNumbers.rejected]: (state) => {},
  },
});

export const { reset } = DashboardSlice.actions;

export default DashboardSlice.reducer;

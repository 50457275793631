import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchProjects = createAsyncThunk(
  "projects/fetchall",
  ({ state, page, search, follow_up_1, follow_up_2, from, to }, { dispatch }) => {
    
    let url = `/projects?state=${state}&page=${page}`

    if (search && search.trim() !== "") {
      url += `&search=${search}`;
    }
    if(follow_up_1){
      url += `&follow_up_1=${follow_up_1}`;
    }
    if (follow_up_2) {
      url += `&follow_up_2=${follow_up_2}`;
    }
    if (from){
      url += `&from=${from}`;
    }
    if (to) {
      url += `&to=${to}`;
    }
    return api
      .get(url,{
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchProjectById = createAsyncThunk(
  "projects/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/projects/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createProject = createAsyncThunk(
  "projects/create",
  (payload, { dispatch }) => {
    return api
      .post("/projects?state=" + payload.state, payload.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateProject = createAsyncThunk(
  "projects/update",
  (payload, { dispatch }) => {
    return api
      .put(
        "/projects/" + payload.id + "?state=" + payload.state,
        payload.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const projectPreviousState = createAsyncThunk(
  "projects/previous/state",
  (payload, { dispatch }) => {
    return api
      .post(
        "/projects/previous-state",
        payload,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteProject = createAsyncThunk(
  "projects/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/projects/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const processToState = createAsyncThunk(
  "projects/process-to-state",
  (payload, { dispatch }) => {
    return api
      .post("/projects/process-to-state", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const setFollowUp = createAsyncThunk(
  "projects/set-follow-up",
  (payload, { dispatch }) => {
    return api
      .post("/projects/set-follow-up", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  projects: null,
  project: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
  totalPages : null,
};

const ProjectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    reset: (state) => {
      state.project = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Projects
    [fetchProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjects.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.projects = payload.data;
        state.totalPages = payload.totalPages
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchProjects.rejected]: (state) => {},

    // Fetch Project By ID
    [fetchProjectById.pending]: (state) => {},
    [fetchProjectById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.project = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchProjectById.rejected]: (state) => {},

    // Create New Project
    [createProject.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createProject.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.projects.unshift(payload.data);
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createProject.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update Project
    [updateProject.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        if (payload.processed_to_next_state) {
          state.projects = state.projects.filter(
            (project) => project._id !== payload.data._id
          );
        } else {
          let index = state.projects.indexOf(
            state.projects.find((project) => project._id === payload.data._id)
          );
          state.projects = [
            ...state.projects.slice(0, index),
            payload.data,
            ...state.projects.slice(index + 1),
          ];
        }
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateProject.rejected]: (state) => {},

    //Update Project Previous
    [projectPreviousState.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [projectPreviousState.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else 
      if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;

        state.projects = state.projects.filter(
          (project) => project._id !== payload.data._id
        );
        
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [projectPreviousState.rejected]: (state) => { },

    // Delete Project
    [deleteProject.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteProject.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.projects = state.projects.filter(
          (project) => project._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteProject.rejected]: (state) => {},

    // Process to state
    [processToState.pending]: (state) => {},
    [processToState.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.message = payload.message;
        state.projects = state.projects.filter(
          (project) => project._id != payload.data.id
        );
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [processToState.rejected]: (state) => {},

    // Set Follow Up
    [setFollowUp.pending]: (state) => {},
    [setFollowUp.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.message = payload.message;
        let index = state.projects.indexOf(
          state.projects.find((project) => project._id === payload.data._id)
        );
        state.projects = [
          ...state.projects.slice(0, index),
          payload.data,
          ...state.projects.slice(index + 1),
        ];
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [setFollowUp.rejected]: (state) => {},
  },
});

export const { reset } = ProjectsSlice.actions;

export default ProjectsSlice.reducer;

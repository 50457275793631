import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchTeasingContacts = createAsyncThunk(
  "teasing-contacts/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/teasing-contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createTeasingContact = createAsyncThunk(
  "teasing-contacts/create",
  (payload, { dispatch }) => {
    return api
      .post("/teasing-contacts", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteTeasingContact = createAsyncThunk(
  "teasing-contacts/delete",
  (payload, { dispatch }) => {
    return api
      .post("/teasing-contacts/destroy", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  teasingContacts: null,
  teasingContact: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const TeasingContactsSlice = createSlice({
  name: "teasingContacts",
  initialState,
  reducers: {
    reset: (state) => {
      state.teasingContact = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Contact Types
    [fetchTeasingContacts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTeasingContacts.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.teasingContacts = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchTeasingContacts.rejected]: (state) => {},

    // Create New Contact Type
    [createTeasingContact.pending]: (state) => {},
    [createTeasingContact.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.message = payload.message;
        state.isSuccess = true;
      } else {
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createTeasingContact.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Delete Contact Type
    [deleteTeasingContact.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteTeasingContact.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.teasingContacts = state.teasingContacts.filter(
          (type) => !payload.data.ids.includes(type._id)
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteTeasingContact.rejected]: (state) => {},
  },
});

export const { reset } = TeasingContactsSlice.actions;

export const resetTeasingContactSlice = TeasingContactsSlice.actions.reset;

export default TeasingContactsSlice.reducer;

import "./Main.css";

import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import { useState } from "react";

import Authenticate from "../../features/Authentication/Authenticate";

function Main() {
  const [SidebarOpen, setSidebarOpen] = useState(true);
  const [SidebarExpanded, setSidebarExpanded] = useState(false);
  const [SidebarRight, setSidebarRight] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => {
      return !prev;
    });
  };

  const toggleSidebarExpanded = () => {
    setSidebarExpanded((prev) => {
      return !prev;
    });
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    setSidebarExpanded(false);
  };

  const openSidebar = () => {
    setSidebarOpen(true);
    setSidebarExpanded(true);
  };

  return (
    <Authenticate>
      <div className="d-flex h-100">
        <Sidebar
          sidebarExpanded={SidebarExpanded}
          sidebarRight={SidebarRight}
          sidebarOpen={SidebarOpen}
          toggleSidebarExpanded={toggleSidebarExpanded}
          closeSidebar={closeSidebar}
          toggleSidebar={toggleSidebar}
        />
        <div
          className="d-flex flex-column flex-grow-1"
          style={{ width: SidebarOpen ? "calc(100vw - 300px)" : "100vw" }}
        >
          <Navbar
            sidebarRight={SidebarRight}
            sidebarOpen={SidebarOpen}
            toggleSidebarExpanded={toggleSidebarExpanded}
            toggleSidebar={toggleSidebar}
            openSidebar={openSidebar}
          />
          <div className="main-outlet flex-grow-1 bg-secondary">
            <Outlet />
          </div>
        </div>
      </div>
    </Authenticate>
  );
}

export default Main;

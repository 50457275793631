import "./Contacts.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchContactFields,
  createContactField,
  fetchContactFieldById,
  updateContactField,
  deleteContactField,
} from "../contacts/ContactFieldsSlice";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import Select from "react-select";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPen,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

function ContactFields() {
  const dispatch = useDispatch();
  const {
    contactFields,
    contactField,
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isFormLoading,
  } = useSelector((store) => store.contactFields);

  const [ContactFieldFormOpen, setContactFieldFormOpen] = useState(false);
  const [ContactFieldForm, setContactFieldForm] = useState({
    name: "",
    type: "",
  });
  const [ContactFieldToEdit, setContactFieldToEdit] = useState();
  const [ContactFieldToDelete, setContactFieldToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (!contactFields) {
      dispatch(fetchContactFields());
    }
    return () => {};
  }, [contactFields]);

  useEffect(() => {
    if (isSuccess || isError) {
      setContactFieldFormOpen(false);
      setContactFieldToDelete(null);
      resetContactFieldForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (ContactFieldToEdit && !contactField) {
      dispatch(fetchContactFieldById(ContactFieldToEdit));
    } else if (ContactFieldToEdit && contactField) {
      setContactFieldForm({
        name: contactField.name,
        type: contactField.type,
      });
      setContactFieldFormOpen(true);
    }
    return () => {};
  }, [contactField, ContactFieldToEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewContactFieldClick = () => {
    dispatch(reset());
    setContactFieldFormOpen(true);
  };

  const onCloseModalClick = () => {
    setContactFieldToEdit(null);
    dispatch(reset());
    setContactFieldFormOpen(false);
    resetContactFieldForm();
  };

  const onMainInputChange = (field, value) => {
    setContactFieldForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetContactFieldForm = () => {
    setContactFieldForm({
      name: "",
      type: "",
    });
  };

  const fieldTypes = [
    {
      label: "Text",
      value: "text",
    },
  ];

  return (
    <>
      <div id="contacts">
        <div className="card bg-primary d-flex gap-2 align-items-center">
          <Link className="no-decoration" to="/ted-contact-fields">
            <h3 className="text-primary">Ted Contact Fields</h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <button
            className="ml-auto btn btn-lg btn-success"
            onClick={onNewContactFieldClick}
          >
            <h3>
              <FontAwesomeIcon icon={faPlus} /> New Contact Field
            </h3>
          </button>
        </div>

        <div className="bg-primary card d-flex gap-2 w-100 align-items-center">
          <input
            data-type="text"
            placeholder="Search"
            value={SearchFilter}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setSearchFilter("");
            }}
          >
            <h4 style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faBan} />
            </h4>
            Clear Filters
          </button>
        </div>

        <div className="rows-container">
          {isLoading && <span>Loading</span>}
          {!isLoading &&
            contactFields?.length > 0 &&
            contactFields
              ?.filter((field) => {
                return field.name.toLowerCase().includes(SearchFilter);
              })
              .map((field) => (
                <div
                  className="row-item d-flex align-items-center gap-1"
                  key={field._id}
                >
                  <div className="fw-semibold text-primary">{field.name}</div>
                  <div className="ml-auto">
                    <button
                      className="btn btn-warning icon-btn"
                      onClick={() => {
                        dispatch(reset());
                        setContactFieldToEdit(field._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        dispatch(reset());
                        setContactFieldToDelete(field._id);
                      }}
                      className="btn icon-btn btn-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
          {!isLoading && contactFields?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add contact field modal */}
      {ContactFieldFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (ContactFieldToEdit) {
                dispatch(
                  updateContactField({
                    id: ContactFieldToEdit,
                    data: ContactFieldForm,
                  })
                );
              } else {
                dispatch(createContactField(ContactFieldForm));
              }
            }}
          >
            <div className="contact-field-modal">
              <h3 className="text-primary title">
                {ContactFieldToEdit ? "Edit" : "New"} Contact Field
              </h3>
              <div className="form-group">
                <label>Name *</label>
                <input
                  data-type="text"
                  placeholder="Name"
                  value={ContactFieldForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                {formErrors?.name && (
                  <span className="text-danger input-error">
                    {formErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Type *</label>
                <Select
                  value={fieldTypes.find(
                    (type) => type.value === ContactFieldForm["type"]
                  )}
                  onChange={(option) => {
                    onMainInputChange("type", option.value);
                  }}
                  options={fieldTypes}
                  placeholder="Type"
                  styles={SelectStyles}
                />
                {formErrors?.type && (
                  <span className="text-danger input-error">
                    {formErrors?.type}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex">
              <button disabled={isFormLoading} className="btn btn-lg btn-success mx-auto" type="submit">
                <h4>{isFormLoading ? 'Loading...' : 'Save'}</h4>
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Contact Field Modal */}
      {ContactFieldToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setContactFieldToDelete(null);
          }}
        >
          <div className="delete-contact-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this contact field?
            </h3>
            <h4 className="text-warning" style={{ marginTop: "1rem" }}>
              Contact data belonging to this field will be deleted.
            </h4>
            <button
              onClick={() => {
                dispatch(deleteContactField(ContactFieldToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ContactFields;

// import "./Users.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchUsers,
  createUser,
  fetchUserById,
  updateUser,
  deleteUser,
  blockUser,
} from "./UsersSlice";
import { fetchRoles } from "../roles/RolesSlice";

import { SelectStyles } from "../../layouts/select/SelectStyles";
import Select from "react-select";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPen,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";

function Users() {
  const dispatch = useDispatch();
  const { users, user, isLoading, isSuccess, isError, message, formErrors, isFormLoading } =
    useSelector((store) => store.users);

  const { roles } = useSelector((store) => store.roles);

  const [UserFormOpen, setUserFormOpen] = useState(false);
  const [UserForm, setUserForm] = useState({
    name: "",
    email: "",
    roles: [],
    password: "",
    password_confirm: "",
  });
  const [UserToEdit, setUserToEdit] = useState();
  const [UserToDelete, setUserToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsers());
    }
    return () => {};
  }, [users]);

  useEffect(() => {
    if (!users) {
      dispatch(fetchRoles());
    }
    return () => {};
  }, [users]);

  useEffect(() => {
    if (isSuccess || isError) {
      setUserFormOpen(false);
      setUserToDelete(null);
      resetUserForm();
    }
    return () => {};
  }, [isSuccess, isError]);

  useEffect(() => {
    if (UserToEdit && !user) {
      dispatch(fetchUserById(UserToEdit));
    } else if (UserToEdit && user) {
      setUserForm({
        name: user.name,
        email: user.email,
        roles: user.roles.map((role) => role._id),
      });
      setUserFormOpen(true);
    }
    return () => {};
  }, [user, UserToEdit]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const onNewUserClick = () => {
    dispatch(reset());
    setUserFormOpen(true);
  };

  const onCloseModalClick = () => {
    setUserToEdit(null);
    dispatch(reset());
    setUserFormOpen(false);
    resetUserForm();
  };

  const onMainInputChange = (field, value) => {
    setUserForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetUserForm = () => {
    setUserForm({
      name: "",
      email: "",
      roles: [],
      password: "",
      password_confirm: "",
    });
  };

  return (
    <>
      <div id="users">
        <div className="card bg-primary d-flex gap-2 align-items-center">
          <Link className="no-decoration" to="/ted-users">
            <h3 className="text-primary">Ted Users</h3>
          </Link>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${
              isError ? "appear danger" : ""
            }`}
          >
            {message}
          </div>
          <button
            className="ml-auto btn btn-lg btn-success"
            onClick={onNewUserClick}
          >
            <h3>
              <FontAwesomeIcon icon={faPlus} /> New User
            </h3>
          </button>
        </div>

        <div className="card bg-primary d-flex gap-2 w-100 align-items-center">
          <input
            data-type="text"
            placeholder="Search"
            value={SearchFilter}
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setSearchFilter("");
            }}
          >
            <h4 style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faBan} />
            </h4>
            Clear Filters
          </button>
        </div>

        <div className="rows-container">
          {isLoading && <span>Loading</span>}
          {!isLoading &&
            users?.length > 0 &&
            users
              ?.filter((user) => {
                return user.name.toLowerCase().includes(SearchFilter);
              })
              .map((user) => (
                <div
                  className="row-item d-flex align-items-center gap-1"
                  key={user._id}
                >
                  <div
                    style={{ flexGrow: "2" }}
                    className="fw-semibold text-primary"
                  >
                    {user.name}
                  </div>
                  <div
                    style={{ flexGrow: "4" }}
                    className="fw-semibold text-primary"
                  >
                    {user.email}
                  </div>
                  <div
                    style={{ flexGrow: "4" }}
                    className="fw-semibold text-secondary"
                  >
                    {user.roles.map((permission, index) => {
                      return (
                        <span key={index}>
                          {permission.name}
                          {index < user.roles.length - 1 ? "," : ""}{" "}
                        </span>
                      );
                    })}
                  </div>
                  <div style={{ flexGrow: "2" }} className="d-flex gap-1">
                    <button
                      title={user.blocked ? "Unblock User" : "Block User"}
                      className={`ml-auto btn icon-btn ${
                        user.blocked ? "btn-warning" : "btn-danger"
                      }`}
                      onClick={() => {
                        dispatch(reset());
                        dispatch(blockUser(user._id));
                      }}
                    >
                      <FontAwesomeIcon icon={faBan} />
                    </button>
                    <button
                      title="Edit User"
                      className="btn btn-warning icon-btn"
                      onClick={() => {
                        dispatch(reset());
                        setUserToEdit(user._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                      title="Delete User"
                      onClick={() => {
                        dispatch(reset());
                        setUserToDelete(user._id);
                      }}
                      className="btn icon-btn btn-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
          {!isLoading && users?.length === 0 && (
            <div className="text-center text-primary fw-semibold">
              <h3>No Data</h3>
            </div>
          )}
        </div>
      </div>

      {/* Add User modal */}
      {UserFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (UserToEdit) {
                dispatch(
                  updateUser({
                    id: UserToEdit,
                    data: UserForm,
                  })
                );
              } else {
                dispatch(createUser(UserForm));
              }
            }}
          >
            <div style={{ width: "25vw" }} className="user-modal">
              <h3 className="text-primary title">
                {UserToEdit ? "Edit" : "New"} User
              </h3>
              <div className="form-group">
                <label>Name *</label>
                <input
                  data-type="text"
                  placeholder="Name"
                  value={UserForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                {formErrors?.name && (
                  <span className="text-danger input-error">
                    {formErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Email *</label>
                <input
                  data-type="text"
                  placeholder="Email *"
                  value={UserForm["email"]}
                  onChange={(event) =>
                    onMainInputChange("email", event.target.value)
                  }
                />
                {formErrors?.email && (
                  <span className="text-danger input-error">
                    {formErrors?.email}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Roles</label>
                <Select
                  value={roles
                    ?.filter((role) => UserForm["roles"].includes(role._id))
                    .map((role) => {
                      return { label: role.name, value: role._id };
                    })}
                  onChange={(options) => {
                    onMainInputChange(
                      "roles",
                      options.map((option) => option.value)
                    );
                  }}
                  options={roles?.map((role) => {
                    return { label: role.name, value: role._id };
                  })}
                  isMulti={true}
                  placeholder="Roles"
                  styles={SelectStyles}
                />
                {formErrors?.roles && (
                  <span className="text-danger input-error">
                    {formErrors?.roles}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  data-type="text"
                  placeholder="Password"
                  value={UserForm["password"]}
                  onChange={(event) =>
                    onMainInputChange("password", event.target.value)
                  }
                />
                {formErrors?.password && (
                  <span className="text-danger input-error">
                    {formErrors?.password}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Password confirm</label>
                <input
                  data-type="text"
                  placeholder="Password confirm"
                  value={UserForm["password_confirm"]}
                  onChange={(event) =>
                    onMainInputChange("password_confirm", event.target.value)
                  }
                />
                {formErrors?.password_confirm && (
                  <span className="text-danger input-error">
                    {formErrors?.password_confirm}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex">
              <button disabled={isFormLoading} className="btn btn-lg btn-success mx-auto" type="submit">
                <h4>{isFormLoading ? 'Loading...' : 'Save'}</h4>
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete User Modal */}
      {UserToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setUserToDelete(null);
          }}
        >
          <div className="delete-user-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this user?
            </h3>
            <button
              onClick={() => {
                dispatch(deleteUser(UserToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Users;

import "./Projects.css";

import { imagesLoaded } from "imagesloaded";
import api from "../../utils/api";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  fetchProjects,
  createProject,
  fetchProjectById,
  updateProject,
  projectPreviousState,
  deleteProject,
  processToState,
  setFollowUp,
} from "./ProjectsSlice";

import { resetTeasingContactSlice } from "../contacts/TeasingContactsSlice";

import { createTeasingContact } from "../contacts/TeasingContactsSlice";

import { fetchPermissions } from "../permissions/PermissionsSlice";

import { Link, useParams } from "react-router-dom";

import projectStates from "../../utils/projectStates";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faPen,
  faBan,
  faClose,
  faFile,
  faBullhorn,
  faDownload,
  faChevronRight,
  fa1,
  fa2,
  faThumbsUp,
  faHand,
  faDollarSign,
  faTh, faList
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../layouts/modal/Modal";
import FileViewer from "../../layouts/file-viewer/FileViewer";
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';

function Projects() {
  const params = useParams();
  const projectState = params.state;
  const dispatch = useDispatch();
  const {
    projects,
    totalPages,
    project,
    isLoading,
    isSuccess,
    isError,
    message,
    formErrors,
    isFormLoading,
  } = useSelector((store) => store.projects);

  const teasingContactSuccess = useSelector(
    (store) => store.teasingContacts.isSuccess
  );
  const teasingContactError = useSelector(
    (store) => store.teasingContacts.isError
  );
  const teasingContactMessage = useSelector(
    (store) => store.teasingContacts.message
  );

  const { permissions } = useSelector((store) => store.permissions);

  const [ProjectFormOpen, setProjectFormOpen] = useState(false);
  const [ProjectForm, setProjectForm] = useState({
    mobile_number: "961 ",
    name: "",
    email: "",
    note: "",
    grade: 1,
    project_name: "",
    meeting_date: "",
    proposal_deadline: "",
    proposal_files: [],
    amount: "",
    sales_files: [],
  });
  const [ProjectToEdit, setProjectToEdit] = useState();
  const [ProjectToDelete, setProjectToDelete] = useState();
  const [SearchFilter, setSearchFilter] = useState("");
  const [FollowUpFilter, setFollowUpFilter] = useState({
    follow_up_1: false,
    follow_up_2: false,
    follow_up_from: "",
    follow_up_to: "",
  });

  const [ContextProject, setContextProject] = useState();
  const [ContextMenuOpen, setContextMenuOpen] = useState(false);
  const [ContextMenuPosition, setContextMenuPosition] = useState([0, 0]);

  const [FileToView, setFileToView] = useState();
  const [viewMode, setViewMode] = useState('grid'); 
  const [page, setPage] = useState(1); 
  const [debouncedSearchFilter, setDebouncedSearchFilter] = useState(SearchFilter);

  useEffect(() => {
    dispatch(fetchProjects({ state: projectState, page: page, search : SearchFilter, follow_up_1: FollowUpFilter.follow_up_1, follow_up_2: FollowUpFilter.follow_up_2, from: FollowUpFilter.follow_up_from, to: FollowUpFilter.follow_up_to }));
  }, [page, FollowUpFilter]);

  const handleChangePage = (event, value) => {
    setPage(value); 
  };


  const toggleView = () => {
    setViewMode(viewMode === 'grid' ? 'cards' : 'grid');
  };


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchFilter(SearchFilter);
    }, 500); 
    return () => clearTimeout(handler);
  }, [SearchFilter]);

  useEffect(() => {
    dispatch(fetchProjects({ state: projectState, search: debouncedSearchFilter, follow_up_1: FollowUpFilter.follow_up_1, follow_up_2: FollowUpFilter.follow_up_2, from: FollowUpFilter.follow_up_from, to: FollowUpFilter.follow_up_to }));
  }, [debouncedSearchFilter]);

  const amountSum = () => {
    var sum = 0;
    for (let i = 0; i < projects?.length; i++) {
      if (projects[i].amount) {
        sum += Number(projects[i].amount["$numberDecimal"]);
      }
    }
    return sum;
  };

  // const filteredProjects = projects?.filter((project) => {
  //   let show = true;
  //   // let search = SearchFilter.toLowerCase().trim();
  //   // if (search) {
  //   //   show = false;
  //   //   if (
  //   //     project.name.toLowerCase().includes(search) ||
  //   //     project.email.toLowerCase().includes(search) ||
  //   //     project.mobile_number.includes(search) ||
  //   //     project.note.toLowerCase().includes(search) ||
  //   //     project.project_name.toLowerCase().includes(search)
  //   //   ) {
  //   //     show = true;
  //   //   }
  //   // }
  //   if (FollowUpFilter.follow_up === 1 && !project.is_follow_up_1) {
  //     show = false;
  //   }
  //   if (FollowUpFilter.follow_up === 2 && !project.is_follow_up_2) {
  //     show = false;
  //   }

  //   if (FollowUpFilter.follow_up_from) {
  //     show = false;
  //     if (
  //       FollowUpFilter.follow_up === 1 &&
  //       project.is_follow_up_1 &&
  //       new Date(project.follow_up_1_at).setHours(0, 0, 0, 0) >=
  //       new Date(FollowUpFilter.follow_up_from).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     } else if (
  //       FollowUpFilter.follow_up === 2 &&
  //       project.is_follow_up_2 &&
  //       new Date(project.follow_up_2_at).setHours(0, 0, 0, 0) >=
  //       new Date(FollowUpFilter.follow_up_from).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     } else if (
  //       FollowUpFilter.follow_up === 0 &&
  //       new Date(project.created_at).setHours(0, 0, 0, 0) >=
  //       new Date(FollowUpFilter.follow_up_from).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     }
  //   }

  //   if (FollowUpFilter.follow_up_to) {
  //     show = false;
  //     if (
  //       FollowUpFilter.follow_up === 1 &&
  //       project.is_follow_up_1 &&
  //       new Date(project.follow_up_1_at).setHours(0, 0, 0, 0) <=
  //       new Date(FollowUpFilter.follow_up_to).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     } else if (
  //       FollowUpFilter.follow_up === 2 &&
  //       project.is_follow_up_2 &&
  //       new Date(project.follow_up_2_at).setHours(0, 0, 0, 0) <=
  //       new Date(FollowUpFilter.follow_up_to).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     } else if (
  //       FollowUpFilter.follow_up === 0 &&
  //       new Date(project.created_at).setHours(0, 0, 0, 0) <=
  //       new Date(FollowUpFilter.follow_up_to).setHours(0, 0, 0, 0)
  //     ) {
  //       show = true;
  //     }
  //   }
  //   return show;
  // });

  useEffect(() => {
    dispatch(fetchProjects({state : projectState, page : page}));
    setSearchFilter("");
    setFollowUpFilter({
      follow_up: 0,
      follow_up_from: "",
      follow_up_to: "",
    });
  }, [projectState]);

  useEffect(() => {
    if (!projects) {
      dispatch(fetchProjects({state: projectState, page: page }));
    }
    return () => { };
  }, [projects]);

  useEffect(() => {
    if (!permissions) {
      dispatch(fetchPermissions());
    }
    return () => { };
  }, [permissions]);

  useEffect(() => {
    if (isSuccess || isError) {
      setProjectFormOpen(false);
      setProjectToDelete(null);
      setProjectToEdit(null);
      resetProjectForm();
    }
    return () => { };
  }, [isSuccess, isError]);

  useEffect(() => {
    if (ProjectToEdit && !project) {
      dispatch(fetchProjectById(ProjectToEdit));
    } else if (ProjectToEdit && project) {
      setProjectForm({
        mobile_number: project.mobile_number,
        name: project.name,
        email: project.email,
        note: project.note,
        grade: project.grade,
        project_name: project.project_name,
        meeting_date: project.meeting_date,
        proposal_deadline: project.proposal_deadline,
        amount: project.amount
          ? Number(project.amount["$numberDecimal"]).toLocaleString("en-US")
          : "",
        proposal_files: [],
        old_proposal_files: project.proposal_files,
        sales_files: [],
        old_sales_files: project.sales_files,
        proposal_files_to_remove: [],
        sales_files_to_remove: [],
      });
      setProjectFormOpen(true);
    }
    return () => { };
  }, [project, ProjectToEdit]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setContextProject(null);
      setContextMenuOpen(false);
    });
    document.addEventListener("contextmenu", () => {
      setContextProject(null);
      setContextMenuOpen(false);
    });

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    function resizeGridItem(item) {
      var grid = document.getElementsByClassName("projects-container")[0];
      var rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
      );
      var rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
      );
      var rowSpan = Math.ceil(
        (item.querySelector(".content").getBoundingClientRect().height +
          rowGap) /
        (rowHeight + rowGap)
      );
      item.style.gridRowEnd = "span " + rowSpan;
    }

    function resizeAllGridItems() {
      var allItems = document.getElementsByClassName("project");
      for (let x = 0; x < allItems.length; x++) {
        resizeGridItem(allItems[x]);
      }
    }

    function resizeInstance(instance) {
      var item = instance.elements[0];
      resizeGridItem(item);
    }

    window.onload = resizeAllGridItems();
    window.addEventListener("resize", resizeAllGridItems);

    var allItems = document.getElementsByClassName("item");
    for (let x = 0; x < allItems.length; x++) {
      imagesLoaded(allItems[x], resizeInstance);
    }
  });

  const onNewProjectClick = () => {
    dispatch(reset());
    setProjectFormOpen(true);
  };

  const onCloseModalClick = () => {
    setProjectToEdit(null);
    dispatch(reset());
    setProjectFormOpen(false);
    resetProjectForm();
  };

  const onMainInputChange = (field, value) => {
    setProjectForm((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const resetProjectForm = () => {
    setProjectForm({
      mobile_number: "961 ",
      name: "",
      email: "",
      note: "",
      grade: 1,
      project_name: "",
      sales_files: [],
      amount: "",
      proposal_files: [],
      meeting_date: "",
      proposal_deadline: "",
    });
  };

  const getFormData = () => {
    let formData = new FormData();
    formData.append("mobile_number", ProjectForm.mobile_number);
    formData.append("name", ProjectForm.name);
    formData.append("note", ProjectForm.note);
    formData.append("email", ProjectForm.email);
    formData.append("grade", ProjectForm.grade);
    formData.append("project_name", ProjectForm.project_name);
    formData.append("amount", ProjectForm.amount.replace(/\D/g, ""));
    formData.append("meeting_date", ProjectForm.meeting_date);
    formData.append("proposal_deadline", ProjectForm.proposal_deadline);
    formData.append("proposal_files_count", ProjectForm.proposal_files.length);
    formData.append("sales_files_count", ProjectForm.sales_files.length);

    for (let i = 0; i < ProjectForm.proposal_files.length; i++) {
      formData.append("proposal_files", ProjectForm.proposal_files[i]);
    }
    for (let i = 0; i < ProjectForm.sales_files.length; i++) {
      formData.append("sales_files", ProjectForm.sales_files[i]);
    }

    if (ProjectToEdit) {
      for (let i = 0; i < ProjectForm.sales_files_to_remove.length; i++) {
        formData.append(
          "sales_files_to_remove",
          ProjectForm.sales_files_to_remove[i]
        );
      }
      for (let i = 0; i < ProjectForm.proposal_files_to_remove.length; i++) {
        formData.append(
          "proposal_files_to_remove",
          ProjectForm.proposal_files_to_remove[i]
        );
      }
    }
    return formData;
  };

  const removeFile = (field, index) => {
    setProjectForm((prev) => {
      return {
        ...prev,
        [field]: [
          ...prev[field].slice(0, index),
          ...prev[field].slice(index + 1),
        ],
      };
    });
  };

  const removeOldFile = (field1, index, field2) => {
    setProjectForm((prev) => {
      return {
        ...prev,
        [field1]: [
          ...prev[field1].slice(0, index),
          ...prev[field1].slice(index + 1),
        ],
        [field2]: [...prev[field2], prev[field1][index].filename],
      };
    });
  };

  const _processToState = (state) => {
    dispatch(reset());
    var data = {
      id: ContextProject,
      state: state,
    };
    if (state === 'sales') {
      data['amount'] = ProcessToSaleAmount.replace(/\D/g, "");
    }
    dispatch(
      processToState(data)
    );
    setContextMenuOpen(false);
    setContextProject(null);
  };

  const _setFollowUp = (follow_up) => {
    dispatch(reset());
    dispatch(
      setFollowUp({
        id: ContextProject,
        follow_up: follow_up,
      })
    );
    setContextMenuOpen(false);
    setContextProject(null);
  };

  const openDropdown = (event) => {
    var toggler = event.target.closest(".dropdown-toggle");
    if (toggler.classList.contains("open")) {
      toggler.nextElementSibling.style.height = "0px";
    } else {
      toggler.nextElementSibling.style.height = `${toggler.nextElementSibling.firstElementChild.offsetHeight}px`;
    }
    toggler.classList.toggle("open");
  };

  const _createTeasingContact = () => {
    dispatch(resetTeasingContactSlice());
    let project = projects.find((project) => project._id === ContextProject);
    dispatch(
      createTeasingContact({
        name: project.name,
        email: project.email,
      })
    );
  };

  const forceDownload = (url, filename) => {
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  };


  const [ProcessToSaleAmount, setProcessToSalesAmount] = useState('');

  const rows = projects && projects.length > 0
    ? projects.map((project) => ({
    id: project._id,
    client: project.name,
    mobileNumber: project.mobile_number,
    email: project.email,
    projectName: project.project_name,
    note: project.note,
    amount: project.amount ? Number(project.amount["$numberDecimal"]).toLocaleString("en-US") : "0",
    projectState : project.state,
    is_follow_up_1 : project.is_follow_up_1,
    is_follow_up_2: project.is_follow_up_2,
    projectGrade : project.grade,
    meetingDate: project.meeting_date ? new Date(project.meeting_date).toLocaleDateString() : 'Invalid Date',
    proposalDeadline: project.proposal_deadline ? new Date(project.proposal_deadline).toLocaleDateString() : 'Invalid Date',
    date: new Date(project.created_at).toLocaleDateString(),
    follow_up_1_date: project.follow_up_1_at ? new Date(project.follow_up_1_at).toLocaleDateString() : 'Invalid Date',
    follow_up_2_date: project.follow_up_2_at ? new Date(project.follow_up_2_at).toLocaleDateString() : 'Invalid Date'

  })) : [];

  const anyNonLeadProjects = projectState !== 'leads';
  const anyMeetingProjects = projectState == 'meetings';
  const amount = projectState == 'sales' || projectState == 'approved' ;
  const proposalDeadline = projectState == 'proposals';
  const follow_up_date = projectState == 'sales';

  const columns = [
    {
      field: 'actions', headerName: 'Actions', width: 60, renderCell: (params) => {
        const handleOpenContextMenu = (event) => {
          event.preventDefault();
          event.stopPropagation();

          let x = event.clientX;
          let y = event.clientY;

          if (x + 240 > window.innerWidth) {
            x -= 220;
          }
          if (y + 90 > window.innerHeight) {
            y -= 70;
          }

          setContextMenuPosition([x, y]);
          setContextProject(params.id);
          setTimeout(() => {
            setContextMenuOpen(true);
          }, 100);
        };

        return (
          <IconButton onClick={handleOpenContextMenu}>
            <MoreVertIcon />
          </IconButton>
        );
      }
    },
    { field: 'client', headerName: 'Client', width: 130 },
    { field: 'mobileNumber', headerName: 'Mobile Number', width: 140 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'note', headerName: 'Note', width: 220 },
    { field: 'date', headerName: 'Date', width: 180 },

   
  ];

  let nameColumnIndex;

  if (anyNonLeadProjects) {
    nameColumnIndex = columns.findIndex(column => column.field === 'client');
    columns.splice(nameColumnIndex + 1, 0, { field: 'projectGrade', headerName: 'Grade', width: 60 });
  }

  if (anyMeetingProjects) {
    nameColumnIndex = columns.findIndex(column => column.field === 'note');
    columns.splice(nameColumnIndex + 1, 0, { field: 'meetingDate', headerName: 'Meeting Date', width: 150 });
  }

  if (amount) {
    nameColumnIndex = columns.findIndex(column => column.field === 'actions');
    columns.splice(nameColumnIndex + 1, 0, { field: 'amount', headerName: 'Amount', width: 100 });
  }

  if (proposalDeadline) {
    nameColumnIndex = columns.findIndex(column => column.field === 'note');
    columns.splice(nameColumnIndex + 1, 0, { field: 'proposalDeadline', headerName: 'Deadline', width: 100 });
  }

  if (anyNonLeadProjects) {
    nameColumnIndex = columns.findIndex(column => column.field === 'client');
    columns.splice(nameColumnIndex + 1, 0, { field: 'projectName', headerName: 'Project Name', width: 150 });
  }

  if (follow_up_date) {
    nameColumnIndex = columns.findIndex(column => column.field === 'projectGrade');
    columns.splice(nameColumnIndex + 1, 0, { field: 'follow_up_1_date', headerName: 'Follow Up 1', width: 180 });
  }

  if (follow_up_date) {
    nameColumnIndex = columns.findIndex(column => column.field === 'follow_up_1_date');
    columns.splice(nameColumnIndex + 1, 0, { field: 'follow_up_2_date', headerName: 'Follow Up 2', width: 180 });
  }

  const getRowClassName = (params) => {
    const { row } = params;
    let className = '' ; 

    if (row.projectState === "sales" && row.is_follow_up_1) {
      className += 'follow-up-1-list';
    } else if (row.projectState === "sales" && row.is_follow_up_2) {
      className += 'follow-up-2-list';
    }

    return className;
  };

  return (
    <>
      <div className="position-relative" id="projects">
        <div className="card bg-primary d-flex gap-2 align-items-center">
          <div>
            <Link className="no-decoration" to="/ted-projects">
              <h3 className="text-primary">
                Ted {projectStates[projectState].name}
              </h3>
            </Link>
            {["sales", "approved"].includes(projectState) && (
              <h4 className="text-highlight">
                {Number(amountSum()).toLocaleString("en-US")} $
              </h4>
            )}
          </div>
          <div
            className={`info-message ${isSuccess ? "appear success" : ""} ${isError ? "appear danger" : ""
              }`}
          >
            {message}
          </div>
          <div
            className={`info-message ${teasingContactSuccess ? "appear success" : ""
              } ${teasingContactError ? "appear danger" : ""}`}
          >
            {teasingContactMessage}
          </div>
          <Link style={{ textDecoration: 'none' }} className="ml-auto btn btn-primary" to="/">Back to Dashboard</Link>
          {!["stopped", "rejected"].includes(projectState) && (
            <button
              className="btn btn-lg btn-success"
              onClick={onNewProjectClick}
            >
              <h3>
                <FontAwesomeIcon icon={faPlus} /> New{" "}
                {projectStates[projectState].singular}
              </h3>
            </button>
          )}
        </div>

        <div className="card bg-primary">
          {projectState === "sales" && (
            <div className="form-group d-flex gap-2 w-100 align-items-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setFollowUpFilter((prev) => {
                    return {
                      follow_up_1: false,
                      follow_up_2: false,
                      follow_up_from: "",
                      follow_up_to: "" };
                  });
                }}
              >
                All
              </button>
              <button
                className={`btn btn-success ${FollowUpFilter.follow_up_1 === true ? "active" : ""
                  }`}
                onClick={() => {
                  setFollowUpFilter((prev) => {
                    return { ...prev, follow_up_1: true, follow_up_2: false, };
                  });
                }}
              >
                Follow Up 1 only
              </button>
              <button
                className={`btn btn-warning ${FollowUpFilter.follow_up_2 === true ? "active" : ""
                  }`}
                onClick={() => {
                  setFollowUpFilter((prev) => {
                    return { ...prev, follow_up_1 :false, follow_up_2: true };
                  });
                }}
              >
                Follow Up 2 only
              </button>
              <input
                value={
                  FollowUpFilter.follow_up_from
                    ? FollowUpFilter.follow_up_from
                    : ""
                }
                onChange={(e) => {
                  setFollowUpFilter((prev) => {
                    return {
                      ...prev,
                      follow_up_from: e.target.value,
                    };
                  });
                }}
                data-type="text"
                type="date"
              />
              <input
                value={
                  FollowUpFilter.follow_up_to ? FollowUpFilter.follow_up_to : ""
                }
                onChange={(e) => {
                  setFollowUpFilter((prev) => {
                    return {
                      ...prev,
                      follow_up_to: e.target.value,
                    };
                  });
                }}
                data-type="text"
                type="date"
              />
            </div>
          )}
          <div className="d-flex gap-2 w-100 align-items-center">
            <input
              data-type="text"
              placeholder="Search"
              value={SearchFilter}
              onChange={(event) => {
                setSearchFilter(event.target.value);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                setSearchFilter("");
                setFollowUpFilter(() => {
                  return {
                    follow_up_1: false,
                    follow_up_2: false,
                    follow_up_from: "",
                    follow_up_to: ""
                  }});
              }}
            >
              <h4 style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faBan} />
              </h4>
              Clear Filters
            </button>
          </div>
        </div>

        <button variant="contained" onClick={toggleView} style={{
          cursor: 'pointer',
          marginBottom : '15px'
        }}>
          <FontAwesomeIcon icon={viewMode === 'grid' ? faTh :  faList} />
        </button>

        {totalPages &&
          <div style={{ marginBottom: '15px' }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
            />
          </div>
        }

        <div className="projects-container"> 
          {isLoading && <span>Loading</span>}
          {!isLoading && viewMode === 'grid' &&
            <div style={{ height: 640, paddingBottom : "20px" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              hideFooter
              getRowClassName={getRowClassName}
            />
            </div>
          }

          {!isLoading && viewMode === 'cards' &&
            projects?.length > 0 &&
            projects.map((project) => (
              <div
                onContextMenu={(e) => {
                  setContextMenuOpen(false);
                  e.preventDefault();
                  e.stopPropagation();
                  let x = e.pageX;
                  let y = e.pageY;
                  if (e.pageX + 240 > window.innerWidth) {
                    x = e.pageX - 220;
                  }
                  if (e.pageY + 90 > window.innerHeight) {
                    y = e.pageY - 70;
                  }
                  setContextMenuPosition([x, y]);
                  setContextProject(project._id);
                  setTimeout(() => {
                    setContextMenuOpen(true);
                  }, 100);
                }}
                className={`project ${projectState === "sales" && project.is_follow_up_1
                  ? "follow-up-1"
                  : ""
                  } ${projectState === "sales" && project.is_follow_up_2
                    ? "follow-up-2"
                    : ""
                  }`}
                key={project._id}
              >
                <div
                  className={`content ${project.state !== "leads" ? "show-grade" : ""
                    }`}
                >
                  <div
                    className={`d-flex align-items-center ${projectState !== "sales" ||
                      (!project.is_follow_up_1 && !project.is_follow_up_2)
                      ? "text-highlight"
                      : ""
                      } ${projectState === "sales" && project.is_follow_up_1
                        ? "text-success"
                        : ""
                      } ${projectState === "sales" && project.is_follow_up_2
                        ? "text-warning"
                        : ""
                      }`}
                  >
                    <h4 className="w-100">{project.name}</h4>
                    {projectState !== "leads" && (
                      <span className="grade">{project.grade}</span>
                    )}
                  </div>
                  <hr className="text-secondary"></hr>
                  <div>
                    <p>
                      <span className="text-secondary">Mobile number: </span>
                      {project.mobile_number}
                    </p>
                    <p>
                      <span className="text-secondary">Email: </span>
                      {project.email}
                    </p>
                    {project.project_name && (
                      <p>
                        <span className="text-secondary">Project name: </span>
                        {project.project_name}
                      </p>
                    )}
                    {project.note && (
                      <p>
                        <span className="text-secondary">Note: </span>
                        {project.note}
                      </p>
                    )}
                    {project.proposal_files &&
                      project.proposal_files.length > 0 && (
                        <div className="dropdown-container">
                          <div
                            className="dropdown-toggle"
                            onClick={(event) => {
                              openDropdown(event);
                            }}
                          >
                            Proposal files
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          <div>
                            <div>
                              {project.proposal_files?.map((file, index) => {
                                return (
                                  <div
                                    className="d-flex flex-align-center"
                                    key={index}
                                  >
                                    <div
                                      className="clickable"
                                      onClick={() => {
                                        setFileToView(file);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faFile} />{" "}
                                      {file.originalname}
                                    </div>

                                    <a
                                      className="ml-auto download-btn"
                                      href={file.object}
                                      download={file.originalname}
                                      target="_blank"
                                    >
                                      <FontAwesomeIcon icon={faDownload} />
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    {project.sales_files && project.sales_files.length > 0 && (
                      <div className="dropdown-container">
                        <div
                          className="dropdown-toggle"
                          onClick={(event) => {
                            openDropdown(event);
                          }}
                        >
                          Sales files
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                        <div>
                          <div>
                            {project.sales_files?.map((file, index) => {
                              return (
                                <div
                                  className="d-flex flex-align-center"
                                  key={index}
                                >
                                  <div
                                    className="clickable"
                                    onClick={() => {
                                      setFileToView(file);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faFile} />{" "}
                                    {file.originalname}
                                  </div>
                                  <a
                                    className="ml-auto download-btn"
                                    href={file.object}
                                    download={file.originalname}
                                    target="_blank"
                                  >
                                    <FontAwesomeIcon icon={faDownload} />
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="dropdown-container">
                      <div
                        onClick={(event) => {
                          openDropdown(event);
                        }}
                        className="dropdown-toggle"
                      >
                        Timeline
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                      <div>
                        <div>
                          <p>
                            Created at:{" "}
                            {new Date(project.created_at).toLocaleString()}
                          </p>
                          {project.processed_to_meetings_at != null && (
                            <p>
                              Processed to meetings at:{" "}
                              {new Date(
                                project.processed_to_meetings_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.processed_to_proposals_at != null && (
                            <p>
                              Processed to proposals at:{" "}
                              {new Date(
                                project.processed_to_proposals_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.processed_to_sales_at != null && (
                            <p>
                              Processed to sales at:{" "}
                              {new Date(
                                project.processed_to_sales_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.follow_up_1_at != null && (
                            <p>
                              Set to follow up 1 at:{" "}
                              {new Date(
                                project.follow_up_1_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.follow_up_2_at != null && (
                            <p>
                              Set to follow up 2 at:{" "}
                              {new Date(
                                project.follow_up_2_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.processed_to_approved_at != null && (
                            <p>
                              Processed to approved at:{" "}
                              {new Date(
                                project.processed_to_approved_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.processed_to_rejected_at != null && (
                            <p>
                              Processed to rejected at:{" "}
                              {new Date(
                                project.processed_to_rejected_at
                              ).toLocaleString()}
                            </p>
                          )}
                          {project.processed_to_stopped_at != null && (
                            <p>
                              Processed to stopped at:{" "}
                              {new Date(
                                project.processed_to_stopped_at
                              ).toLocaleString()}
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                      
                        <div>
                          <p>
                        <span className="text-secondary">Created at:{" "}</span>
                        {new Date(project.created_at).toLocaleDateString()}
                          </p>
                          {project.processed_to_meetings_at != null && (
                            <p>
                          <span className="text-secondary">Processed to meetings at:{" "}</span>
                              {new Date(
                                project.processed_to_meetings_at
                              ).toLocaleDateString()}
                            </p>
                          )}
                          {project.processed_to_proposals_at != null && (
                            <p>
                          <span className="text-secondary">Processed to proposals at:{" "}</span>
                              {new Date(
                                project.processed_to_proposals_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.processed_to_sales_at != null && (
                            <p>
                          <span className="text-secondary">  Processed to sales at:{" "}</span>
                              {new Date(
                                project.processed_to_sales_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.follow_up_1_at != null && (
                            <p>
                          <span className="text-secondary"> Set to follow up 1 at:{" "} </span>
                              {new Date(
                                project.follow_up_1_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.follow_up_2_at != null && (
                            <p>
                          <span className="text-secondary"> Set to follow up 2 at:{" "} </span>
                              {new Date(
                                project.follow_up_2_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.processed_to_approved_at != null && (
                            <p>
                          <span className="text-secondary"> Processed to approved at:{" "} </span>
                              {new Date(
                                project.processed_to_approved_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.processed_to_rejected_at != null && (
                            <p>
                          <span className="text-secondary"> Processed to rejected at:{" "} </span>
                              {new Date(
                                project.processed_to_rejected_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                          {project.processed_to_stopped_at != null && (
                            <p>
                          <span className="text-secondary">Processed to stopped at:{" "} </span>
                              {new Date(
                                project.processed_to_stopped_at
                          ).toLocaleDateString()}
                            </p>
                          )}
                        </div>
                  </div>
                  {!["leads", "rejected", "stopped"].includes(projectState) && (
                    <hr className="text-secondary"></hr>
                  )}
                  {projectState === "meetings" && (
                    <div className="text-center">
                      <h4 className="text-highlight">
                        {new Date(project.meeting_date).toLocaleDateString()}
                      </h4>
                    </div>
                  )}
                  {projectState === "proposals" && (
                    <div className="text-center">
                      <h4 className="text-highlight">
                        {new Date(
                          project.proposal_deadline
                        ).toLocaleDateString()}
                      </h4>
                    </div>
                  )}
                  {(projectState === "sales" ||
                    projectState === "approved") && (
                      <div className="text-center">
                        <h4
                          className={`${projectState !== "sales" ||
                            (!project.is_follow_up_1 && !project.is_follow_up_2)
                            ? "text-highlight"
                            : ""
                            } ${projectState === "sales" && project.is_follow_up_1
                              ? "text-success"
                              : ""
                            } ${project.is_follow_up_2 ? "text-warning" : ""}`}
                        >
                          {project.amount
                            ? Number(
                              project.amount["$numberDecimal"]
                            ).toLocaleString("en-US")
                            : 0}{" "}
                          $
                        </h4>
                      </div>
                    )}
                </div>
              </div>
            ))}
        </div>
        {!isLoading && projects?.length === 0 && (
          <div className="text-center text-primary fw-semibold">
            <h3>No Data</h3>
          </div>
        )}

        {ContextMenuOpen && (
          <div
            id="context-menu"
            style={{
              left: `${ContextMenuPosition[0]}px`,
              top: `${ContextMenuPosition[1]}px`,
            }}
            className="context-menu"
          >
            {!["stopped", "rejected"].includes(projectState) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(reset());
                  setContextMenuOpen(false);
                  setProjectToEdit(ContextProject);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
                Edit
              </div>
            )}
            <div
              onClick={() => {
                _createTeasingContact();
              }}
            >
              <FontAwesomeIcon icon={faBullhorn} />
              Create Teasing Contact
            </div>
            {
              projectState === 'proposals' && <>
                <div onClick={(e) => e.stopPropagation()} className="amount-input" style={{ backgroundColor: 'white' }}>
                  <input
                    style={{ boxShadow: 'none', fontSize: '12px', padding: '5px 10px' }}
                    data-type="text"
                    placeholder="Amount"
                    name="amount"
                    value={ProcessToSaleAmount}
                    onChange={(event) => {
                      setProcessToSalesAmount(Number(
                        event.target.value.replace(/\D/g, "")
                      ).toLocaleString("en-US"));
                    }}
                  />
                </div>
                <div onClick={(e) => {
                  e.stopPropagation();
                  _processToState("sales");
                }}>
                  <FontAwesomeIcon icon={faDollarSign} />Process to Sales</div>
              </>
            }
            {projectState === "sales" && (
              <>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    _setFollowUp(1);
                  }}
                >
                  <FontAwesomeIcon icon={fa1} />
                  Set to Follow up 1
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    _setFollowUp(2);
                  }}
                >
                  <FontAwesomeIcon icon={fa2} />
                  Set to Follow up 2
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    _processToState("approved");
                  }}
                >
                  <FontAwesomeIcon icon={faThumbsUp} />
                  Process to Approved
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    _processToState("rejected");
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                  Process to Rejected
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    _processToState("stopped");
                  }}
                >
                  <FontAwesomeIcon icon={faHand} />
                  Process to Stopped
                </div>
              </>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(reset());
                setContextMenuOpen(false);
                setProjectToDelete(ContextProject);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              Delete
            </div>
          </div>
        )}
      </div>

      {/* Add Project modal */}
      {ProjectFormOpen && (
        <Modal classes="bg-secondary" onCloseModalClick={onCloseModalClick}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (ProjectToEdit) {
                dispatch(
                  updateProject({
                    state: projectState,
                    id: ProjectToEdit,
                    data: getFormData(),
                  })
                );
              } else {
                dispatch(
                  createProject({
                    state: projectState,
                    data: getFormData(),
                  })
                );
              }
            }}
          >
            <div className="project-modal">
              <h3 className="text-primary title">
                {ProjectToEdit ? "Edit" : "New"}{" "}
                {projectStates[projectState].singular}
              </h3>
              {(["sales", "approved"].includes(projectState) ||
                (projectState === "proposals" && ProjectToEdit)) && (
                  <>
                    <div className="position-relative form-group">
                      <label>Sales Files</label>
                      <input
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          pointerEvents: "none",
                          opacity: "0",
                        }}
                        data-type="text"
                        type="file"
                        name="sales_files"
                        multiple={true}
                        placeholder="Sales Files"
                        onChange={(event) => {
                          setProjectForm((prev) => {
                            return {
                              ...prev,
                              sales_files: [
                                ...Object.values(event.target.files),
                                ...prev.sales_files,
                              ],
                            };
                          });
                        }}
                      />
                      <input
                        onClick={() => {
                          document
                            .querySelector('input[name="sales_files"]')
                            .click();
                        }}
                        data-type="text"
                        placeholder="Sales Files"
                      />
                      {ProjectForm["sales_files"]?.map((file, index) => {
                        return (
                          <div key={index} className="d-flex align-items-center">
                            <p>
                              <FontAwesomeIcon icon={faFile} /> {file.name}
                            </p>
                            <FontAwesomeIcon
                              style={{ padding: "0.5rem 0.3rem" }}
                              className="clickable ml-auto text-danger"
                              icon={faClose}
                              onClick={() => {
                                removeFile("sales_files", index);
                              }}
                            />
                          </div>
                        );
                      })}
                      {ProjectForm["old_sales_files"]?.map((file, index) => {
                        return (
                          <div key={index} className="d-flex align-items-center">
                            <p>
                              <FontAwesomeIcon icon={faFile} />{" "}
                              {file.originalname}
                            </p>
                            <FontAwesomeIcon
                              style={{ padding: "0.5rem 0.3rem" }}
                              className="clickable ml-auto text-danger"
                              icon={faClose}
                              onClick={() => {
                                removeOldFile(
                                  "old_sales_files",
                                  index,
                                  "sales_files_to_remove"
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                      {formErrors?.sales_files && (
                        <span className="text-danger input-error">
                          {formErrors?.sales_files}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Amount</label>
                      <div className="amount-input">
                        <input
                          data-type="text"
                          placeholder="Amount"
                          name="amount"
                          value={ProjectForm["amount"]}
                          onChange={(event) => {
                            onMainInputChange(
                              "amount",
                              Number(
                                event.target.value.replace(/\D/g, "")
                              ).toLocaleString("en-US")
                            );
                          }}
                        />
                      </div>
                      {formErrors?.amount && (
                        <span className="text-danger input-error">
                          {formErrors?.amount}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <hr className="text-secondary" />
                    </div>
                  </>
                )}
              {(["sales", "approved", "proposals"].includes(projectState) ||
                (projectState === "meetings" && ProjectToEdit)) && (
                  <>
                    <div className="position-relative form-group">
                      <label>Proposal Files</label>
                      <input
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          pointerEvents: "none",
                          opacity: "0",
                        }}
                        data-type="text"
                        type="file"
                        name="proposal_files"
                        multiple={true}
                        placeholder="Proposal Files"
                        onChange={(event) => {
                          setProjectForm((prev) => {
                            return {
                              ...prev,
                              proposal_files: [
                                ...Object.values(event.target.files),
                                ...prev.proposal_files,
                              ],
                            };
                          });
                        }}
                      />
                      <input
                        onClick={() => {
                          document
                            .querySelector('input[name="proposal_files"]')
                            .click();
                        }}
                        data-type="text"
                        placeholder="Proposal Files"
                      />
                      {formErrors?.proposal_files_count && (
                        <span className="text-danger input-error">
                          {formErrors?.proposal_files_count}
                        </span>
                      )}
                      {ProjectForm["proposal_files"]?.map((file, index) => {
                        return (
                          <div key={index} className="d-flex align-items-center">
                            <p>
                              <FontAwesomeIcon icon={faFile} /> {file.name}
                            </p>
                            <FontAwesomeIcon
                              style={{ padding: "0.5rem 0.3rem" }}
                              className="clickable ml-auto text-danger"
                              icon={faClose}
                              onClick={() => {
                                removeFile("proposal_files", index);
                              }}
                            />
                          </div>
                        );
                      })}
                      {ProjectForm["old_proposal_files"]?.map((file, index) => {
                        return (
                          <div key={index} className="d-flex align-items-center">
                            <p>
                              <FontAwesomeIcon icon={faFile} />{" "}
                              {file.originalname}
                            </p>
                            <FontAwesomeIcon
                              style={{ padding: "0.5rem 0.3rem" }}
                              className="clickable ml-auto text-danger"
                              icon={faClose}
                              onClick={() => {
                                removeOldFile(
                                  "old_proposal_files",
                                  index,
                                  "proposal_files_to_remove"
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="form-group">
                      <label>Proposal Deadline</label>
                      <input
                        data-type="text"
                        type="date"
                        placeholder="Proposal Deadline"
                        value={ProjectForm["proposal_deadline"]}
                        onChange={(event) => {
                          onMainInputChange(
                            "proposal_deadline",
                            event.target.value
                          );
                        }}
                      />
                      {formErrors?.proposal_deadline && (
                        <span className="text-danger input-error">
                          {formErrors?.proposal_deadline}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <hr className="text-secondary" />
                    </div>
                  </>
                )}
              {(projectState != "leads" || ProjectToEdit) && (
                <>
                  <div className="form-group">
                    <label>Meeting Date</label>
                    <input
                      data-type="text"
                      type="datetime-local"
                      value={ProjectForm["meeting_date"]}
                      onChange={(event) => {
                        onMainInputChange("meeting_date", event.target.value);
                      }}
                    />
                    {formErrors?.meeting_date && (
                      <span className="text-danger input-error">
                        {formErrors?.meeting_date}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Grade{" "}
                      <span
                        style={{ paddingLeft: "1rem" }}
                        className="text-primary fw-semibold"
                      >
                        {ProjectForm["grade"]}
                      </span>
                    </label>
                    <input
                      type="range"
                      min="1"
                      max="10"
                      placeholder="Grade"
                      value={ProjectForm["grade"]}
                      onChange={(event) =>
                        onMainInputChange("grade", event.target.value)
                      }
                    />
                    {formErrors?.grade && (
                      <span className="text-danger input-error">
                        {formErrors?.grade}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Project Name</label>
                    <input
                      data-type="text"
                      placeholder="Project Name"
                      value={ProjectForm["project_name"]}
                      onChange={(event) =>
                        onMainInputChange("project_name", event.target.value)
                      }
                    />
                    {formErrors?.project_name && (
                      <span className="text-danger input-error">
                        {formErrors?.project_name}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <hr className="text-secondary" />
                  </div>
                </>
              )}
              <div className="form-group">
                <label>Mobile Number *</label>
                <input
                  data-type="text"
                  placeholder="Mobile Number *"
                  value={ProjectForm["mobile_number"]}
                  onChange={(event) =>
                    onMainInputChange("mobile_number", event.target.value)
                  }
                />
                {formErrors?.mobile_number && (
                  <span className="text-danger input-error">
                    {formErrors?.mobile_number}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Client *</label>
                <input
                  data-type="text"
                  placeholder="Client *"
                  value={ProjectForm["name"]}
                  onChange={(event) =>
                    onMainInputChange("name", event.target.value)
                  }
                />
                {formErrors?.name && (
                  <span className="text-danger input-error">
                    {formErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Email *</label>
                <input
                  data-type="text"
                  placeholder="Email *"
                  value={ProjectForm["email"]}
                  onChange={(event) =>
                    onMainInputChange("email", event.target.value)
                  }
                />
                {formErrors?.email && (
                  <span className="text-danger input-error">
                    {formErrors?.email}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Note</label>
                <textarea
                  data-type="text"
                  placeholder="Note"
                  value={ProjectForm["note"]}
                  onChange={(event) =>
                    onMainInputChange("note", event.target.value)
                  }
                ></textarea>
                {formErrors?.note && (
                  <span className="text-danger input-error">
                    {formErrors?.note}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="mx-auto d-flex gap-1">
                {ProjectToEdit && projectStates[projectState].previous && (
                  <button
                    disabled={isFormLoading}
                    onClick={() => {
                      dispatch(
                        projectPreviousState({
                          state: projectStates[projectState].previous,
                          id: ProjectToEdit,
                        })
                      );
                    }}
                    type="button"
                    className="btn btn btn-danger"
                  >
                    <h4>
                      {isFormLoading ? 'Loading...' : `revert to ${projectStates[projectStates[projectState].previous].name}`}
                    </h4>
                  </button>
                )}
                <button disabled={isFormLoading} type="submit" className="btn btn btn-success">
                  <h4>{isFormLoading ? 'Loading...' : 'Save'}</h4>
                </button>
                {ProjectToEdit && projectStates[projectState].next && (
                  <button
                    disabled={isFormLoading}
                    onClick={() => {
                      let formdata = getFormData();
                      formdata.append(
                        "state",
                        projectStates[projectState].next
                      );
                      dispatch(
                        updateProject({
                          state: projectState,
                          id: ProjectToEdit,
                          data: formdata,
                        })
                      );
                    }}
                    type="button"
                    className="btn btn btn-success"
                  >
                    <h4>
                      {isFormLoading ? 'Loading...' : `Save and Process to ${projectStates[projectStates[projectState].next].name}`}
                    </h4>
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal>
      )}

      {/* Delete Project Modal */}
      {ProjectToDelete && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setProjectToDelete(null);
          }}
        >
          <div className="delete-project-modal text-center">
            <h3 className="text-primary">
              Are you sure you want to delete this project?
            </h3>
            <button
              onClick={() => {
                dispatch(deleteProject(ProjectToDelete));
              }}
              className="btn btn-lg btn-danger mx-auto"
            >
              <h3>Delete</h3>
            </button>
          </div>
        </Modal>
      )}

      {/* Delete Project Modal */}
      {FileToView && (
        <Modal
          classes="bg-primary"
          onCloseModalClick={() => {
            setFileToView(null);
          }}
        >
          <FileViewer file={FileToView} />
        </Modal>
      )}
    </>
  );
}

export default Projects;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

export const fetchUser = createAsyncThunk(
  "authentication/fetchuser",
  (paylaoad, { dispatch }) => {
    return api
      .get("/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const login = createAsyncThunk("authentication/login", (payload) => {
  return api
    .post("/auth/login", payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
});

const initialState = {
  authUser: null,
  formErrors: null,
  message: null,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
  isAuthenticated: true,
};

const AuthenticationSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    reset: (state) => {
      state.isAuthenticated = true;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },

    logout: (state) => {
      localStorage.setItem("token", null);
      state.isAuthenticated = false;
      state.authUser = null;
    },
  },
  extraReducers: {
    // Fetch All Contact Types
    [fetchUser.pending]: (state) => {},
    [fetchUser.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.authUser = payload.data;
      } else {
      }
    },
    [fetchUser.rejected]: (state) => {},

    // Create New Contact Type
    [login.pending]: (state) => {
      state.formErrors = null;
      state.message = null;
      state.isFormLoading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = null;
        state.formErrors = null;
        localStorage.setItem("token", payload.data.token);
        state.isAuthenticated = true;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [login.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },
  },
});

export const { reset, logout } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;

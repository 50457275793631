import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchContacts = createAsyncThunk(
  "contacts/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchContactById = createAsyncThunk(
  "contacts/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/contacts/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createContact = createAsyncThunk(
  "contacts/create",
  (payload, { dispatch }) => {
    return api
      .post("/contacts", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateContact = createAsyncThunk(
  "contacts/update",
  (payload, { dispatch }) => {
    return api
      .put("/contacts/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/contacts/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchAdditionalFields = createAsyncThunk(
  "contacts/fetchAdditionalFields",
  (payload, { dispatch }) => {
    return api
      .get("/contacts/additional-fields", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const highlighContact = createAsyncThunk(
  "contacts/highlight",
  (payload, { dispatch }) => {
    return api
      .get("/contacts/" + payload + "/highlight/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const importContacts = createAsyncThunk(
  "contacts/import",
  (payload, { dispatch }) => {
    return api
      .post("/contacts/import", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  contacts: null,
  contact: null,
  additionalFields: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const ContactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    reset: (state) => {
      state.contact = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Contacts
    [fetchContacts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchContacts.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.contacts = [...payload.data];
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContacts.rejected]: (state) => {},

    // Fetch Contact By ID
    [fetchContactById.pending]: (state) => {},
    [fetchContactById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.contact = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContactById.rejected]: (state) => {},

    // Create New Contact
    [createContact.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createContact.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contacts[state.contacts.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createContact.rejected]: (state) => {},

    // Update Contact Type
    [updateContact.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updateContact.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.contacts.indexOf(
          state.contacts.find((contact) => contact._id === payload.data._id)
        );
        state.contacts = [
          ...state.contacts.slice(0, index),
          payload.data,
          ...state.contacts.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateContact.rejected]: (state) => {},

    // Delete Contact Type
    [deleteContact.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteContact.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contacts = state.contacts.filter(
          (type) => type._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteContact.rejected]: (state) => {},

    // Fetch All Contact Additional Fields
    [fetchAdditionalFields.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAdditionalFields.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.additionalFields = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchAdditionalFields.rejected]: (state) => {},

    // Highlight/Unhighlight Contact
    [highlighContact.pending]: (state) => {},
    [highlighContact.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        let index = state.contacts.indexOf(
          state.contacts.find((contact) => contact._id === payload.data.id)
        );
        state.contacts = [
          ...state.contacts.slice(0, index),
          {
            ...state.contacts[index],
            is_highlighted: payload.data.is_highlighted,
          },
          ...state.contacts.slice(index + 1),
        ];
        state.message = payload.message;
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [highlighContact.rejected]: (state) => {},

    // Import Contacts
    [importContacts.pending]: (state) => {
      // state.formErrors = null;
      // state.isFormLoading = true;
    },
    [importContacts.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contacts = [...payload.data, ...state.contacts];
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [importContacts.rejected]: (state) => {},
  },
});

export const { reset } = ContactsSlice.actions;

export default ContactsSlice.reducer;

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchUser } from "./AthenticationSlice";

function Authenticate({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pathPermissionMapping = {
    "/ted-users": "users",
    "/ted-roles": "roles",
    "/ted-contacts": "contacts",
    "/ted-contact-types": "contact-types",
    "/ted-contact-fields": "contact-fields",
    "/ted-teasing-contacts": "teasing-contacts",
    "/ted-projects/leads": "leads",
    "/ted-projects/meetings": "meetings",
    "/ted-projects/proposals": "proposals",
    "/ted-projects/sales": "sales",
    "/ted-projects/approved": "approved",
    "/ted-projects/rejected": "rejected",
    "/ted-projects/stopped": "stopped",
  };

  const { isAuthenticated, authUser } = useSelector(
    (store) => store.authentication
  );

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      authUser &&
      pathPermissionMapping[location.pathname] &&
      !authUser?.permissions.includes(pathPermissionMapping[location.pathname])
    ) {
      console.log(location.pathname);
      navigate("/403");
    }
  }, [authUser, location]);

  return <>{children}</>;
}

export default Authenticate;

const projectStates = {
  leads: {
    name: "Leads",
    singular: "Lead",
    next: "meetings",
    previous : ""
  },
  meetings: {
    name: "Meetings",
    singular: "Meeting",
    next: "proposals",
    previous: "leads"
  },
  proposals: {
    name: "Proposals",
    singular: "Proposal",
    next: "sales",
    previous: "meetings"
  },
  sales: {
    name: "Sales",
    singular: "Sale",
    next: "approved",
    previous: "proposals"
  },
  approved: {
    name: "Approved Projects",
    singular: "Approved Project",
    next: "",
    previous: ""
  },
  rejected: {
    name: "Rejected Projects",
    singular: "Rejected Project",
    next: "",
    previous: ""
  },
  stopped: {
    name: "Stopped Projects",
    singular: "Stopped Project",
    next: "",
    previous: ""
  },
};

export default projectStates;

import "./Modal.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Modal({ children, onCloseModalClick, classes }) {
  return (
    <div className="modal" onClick={onCloseModalClick}>
      <div className={classes} onClick={(event) => {event.stopPropagation()}}>
        <button
          onClick={onCloseModalClick}
          className="btn icon-btn btn-danger btn-close close-modal"
        >
          <h3>
            <FontAwesomeIcon icon={faXmark} />
          </h3>
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;

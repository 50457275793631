import "./Dashboard.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMainNumbers } from "./DashboardSlice";

function Dashboard() {
  const dispatch = useDispatch();

  const { mainNumbers } = useSelector((store) => store.dashboard);

  useEffect(() => {
    dispatch(fetchMainNumbers());
  }, []);

  useEffect(() => {
    if (!mainNumbers) {
      dispatch(fetchMainNumbers());
    }
    console.log(mainNumbers);
  }, [mainNumbers]);

  return (
    <div className="dashboard">
      <div className="card bg-primary">
        <h3>Dashboard</h3>
      </div>

      {/* PROJECTS */}
      <div className="card bg-primary project-states-container">
        <Link
          className="text-highlight no-decoration"
          to={"/ted-projects/leads"}
          style={{ zIndex: "4" }}
        >
          <div></div>
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center flex-column">
            <h2>
              {mainNumbers?.project_counts?.leads[0]
                ? mainNumbers?.project_counts?.leads[0].count
                : 0}
            </h2>
            <h4>Leads</h4>
          </div>
        </Link>
        <Link
          className="text-highlight no-decoration"
          to={"/ted-projects/meetings"}
          style={{ zIndex: "3" }}
        >
          <div></div>
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center flex-column">
            <h2>
              {mainNumbers?.project_counts?.meetings[0]
                ? mainNumbers?.project_counts?.meetings[0].count
                : 0}
            </h2>
            <h4>Meetings</h4>
          </div>
        </Link>
        <Link
          className="text-highlight no-decoration"
          to={"/ted-projects/proposals"}
          style={{ zIndex: "2" }}
        >
          <div></div>
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center flex-column">
            <h2>
              {mainNumbers?.project_counts?.proposals[0]
                ? mainNumbers?.project_counts?.proposals[0].count
                : 0}
            </h2>
            <h4>Proposals</h4>
          </div>
        </Link>
        <Link
          className="text-highlight no-decoration"
          to={"ted-projects/sales"}
          style={{ zIndex: "1" }}
        >
          <div></div>
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center flex-column">
            <h2>
              {mainNumbers?.project_counts?.sales[0]
                ? mainNumbers?.project_counts?.sales[0].count
                : 0}
            </h2>
            <h4>Sales</h4>
          </div>
        </Link>
        <Link
          className="text-highlight no-decoration"
          to={"/ted-projects/approved"}
        >
          <div></div>
          <div className="w-100 h-100 position-relative d-flex align-items-center justify-content-center flex-column">
            <h2>
              {mainNumbers?.project_counts?.approved[0]
                ? mainNumbers?.project_counts?.approved[0].count
                : 0}
            </h2>
            <h4>Approved</h4>
          </div>
        </Link>
      </div>

      <div className="card bg-primary">
        <div className="gap-2 text-highlight d-flex wrap">
          <Link
            to={"/ted-projects/rejected"}
            className="numbox text-highlight no-decoration flex-grow-1 bg-secondary text-center shadow"
          >
            <h2>
              {mainNumbers?.project_counts?.rejected[0]
                ? mainNumbers?.project_counts?.rejected[0].count
                : 0}
            </h2>
            <h4>Rejected</h4>
          </Link>
          <Link
            to={"/ted-projects/stopped"}
            className="numbox flex-grow-1 text-highlight no-decoration bg-secondary text-center shadow"
          >
            <h2>
              {mainNumbers?.project_counts?.stopped[0]
                ? mainNumbers?.project_counts?.stopped[0].count
                : 0}
            </h2>
            <h4>Stopped</h4>
          </Link>
          <Link
            to={"/ted-contacts"}
            className="numbox flex-grow-1 text-highlight no-decoration bg-secondary text-center shadow"
          >
            <h2>{mainNumbers?.contacts_count}</h2>
            <h4>Contacts</h4>
          </Link>
          <Link
            to={"/ted-teasing-contacts"}
            className="numbox flex-grow-1 text-highlight no-decoration bg-secondary text-center shadow"
          >
            <h2>{mainNumbers?.teasing_contacts_count}</h2>
            <h4>Teasing Contacts</h4>
          </Link>
        </div>
      </div>

      <div className="card bg-primary">
        <div className="gap-2 text-highlight d-flex wrap">
          <Link
            to={"/ted-projects/sales"}
            className="numbox text-highlight flex-grow-1 no-decoration bg-secondary text-center shadow"
          >
            <h2>
              {mainNumbers?.sales_revenue[0]
                ? Number(mainNumbers?.sales_revenue[0].count['$numberDecimal']).toLocaleString('en-US')
                : 0}{" "}
              $
            </h2>
            <h4>Sales</h4>
          </Link>
          <Link
            to={"/ted-projects/approved"}
            className="numbox text-highlight flex-grow-1 no-decoration bg-secondary text-center shadow"
          >
            <h2>
            {mainNumbers?.approved_revenue[0]
                ? Number(mainNumbers?.approved_revenue[0].count['$numberDecimal']).toLocaleString('en-US')
                : 0}{" "}
              $
            </h2>
            <h4>Approved</h4>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

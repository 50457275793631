import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchContactTypes = createAsyncThunk(
  "contact-types/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/contact-types", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchContactTypeById = createAsyncThunk(
  "contact-types/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/contact-types/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createContactType = createAsyncThunk(
  "contact-types/create",
  (payload, { dispatch }) => {
    return api
      .post("/contact-types", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateContactType = createAsyncThunk(
  "contact-types/update",
  (payload, { dispatch }) => {
    return api
      .put("/contact-types/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteContactType = createAsyncThunk(
  "contact-types/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/contact-types/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  contactTypes: null,
  contactType: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const ContactTypesSlice = createSlice({
  name: "contacttypes",
  initialState,
  reducers: {
    reset: (state) => {
      state.contactType = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
    },
  },
  extraReducers: {
    // Fetch All Contact Types
    [fetchContactTypes.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchContactTypes.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.contactTypes = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContactTypes.rejected]: (state) => {},

    // Fetch Contact Type By ID
    [fetchContactTypeById.pending]: (state) => {},
    [fetchContactTypeById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.contactType = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContactTypeById.rejected]: (state) => {},

    // Create New Contact Type
    [createContactType.pending]: (state) => {
      state.isFormLoading = true;
    },
    [createContactType.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contactTypes[state.contactTypes.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createContactType.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update Contact Type
    [updateContactType.pending]: (state) => {
      state.isFormLoading = true;
    },
    [updateContactType.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.contactTypes.indexOf(
          state.contactTypes.find((type) => type._id === payload.data._id)
        );
        state.contactTypes = [
          ...state.contactTypes.slice(0, index),
          payload.data,
          ...state.contactTypes.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateContactType.rejected]: (state) => {},

    // Delete Contact Type
    [deleteContactType.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteContactType.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contactTypes = state.contactTypes.filter(
          (type) => type._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteContactType.rejected]: (state) => {},
  },
});

export const { reset } = ContactTypesSlice.actions;

export default ContactTypesSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchPermissions = createAsyncThunk(
  "permissions/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/permissions", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchPermissionById = createAsyncThunk(
  "permissions/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/permissions/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createPermission = createAsyncThunk(
  "permissions/create",
  (payload, { dispatch }) => {
    return api
      .post("/permissions", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updatePermission = createAsyncThunk(
  "permissions/update",
  (payload, { dispatch }) => {
    return api
      .put("/permissions/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deletePermission = createAsyncThunk(
  "permissions/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/permissions/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  permissions: null,
  permissison: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const PermissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    reset: (state) => {
      state.permissison = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Permissions
    [fetchPermissions.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPermissions.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.permissions = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchPermissions.rejected]: (state) => {},

    // Fetch Permission By ID
    [fetchPermissionById.pending]: (state) => {},
    [fetchPermissionById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.permissison = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchPermissionById.rejected]: (state) => {},

    // Create New Permission
    [createPermission.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createPermission.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.permissions[state.permissions.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createPermission.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update Permission
    [updatePermission.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updatePermission.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.permissions.indexOf(
          state.permissions.find(
            (permission) => permission._id === payload.data._id
          )
        );
        state.permissions = [
          ...state.permissions.slice(0, index),
          payload.data,
          ...state.permissions.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updatePermission.rejected]: (state) => {},

    // Delete Permission
    [deletePermission.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deletePermission.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.permissions = state.permissions.filter(
          (permission) => permission._id !== payload.data.id
        );
        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deletePermission.rejected]: (state) => {},
  },
});

export const { reset } = PermissionsSlice.actions;

export default PermissionsSlice.reducer;

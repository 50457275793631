import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../utils/api";

import { logout } from "../Authentication/AthenticationSlice";

export const fetchContactFields = createAsyncThunk(
  "contact-fields/fetchall",
  (payload, { dispatch }) => {
    return api
      .get("/contact-fields", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const fetchContactFieldById = createAsyncThunk(
  "contact-fields/fetch",
  (payload, { dispatch }) => {
    return api
      .get("/contact-fields/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const createContactField = createAsyncThunk(
  "contact-fields/create",
  (payload, { dispatch }) => {
    return api
      .post("/contact-fields", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const updateContactField = createAsyncThunk(
  "contact-fields/update",
  (payload, { dispatch }) => {
    return api
      .put("/contact-fields/" + payload.id, payload.data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

export const deleteContactField = createAsyncThunk(
  "contact-fields/delete",
  (payload, { dispatch }) => {
    return api
      .delete("/contact-fields/" + payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "unauthenticated") {
          dispatch(logout());
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
);

const initialState = {
  contactFields: null,
  contactField: null,
  formErrors: null,
  message: null,
  isLoading: false,
  isFormLoading: false,
  isSuccess: false,
  isError: false,
};

const ContactFieldsSlice = createSlice({
  name: "contactFields",
  initialState,
  reducers: {
    reset: (state) => {
      state.contactField = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
      state.isFormLoading = false;
      state.formErrors = null;
    },
  },
  extraReducers: {
    // Fetch All Contact Types
    [fetchContactFields.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchContactFields.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.isLoading = false;
        state.contactFields = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContactFields.rejected]: (state) => {},

    // Fetch Contact Type By ID
    [fetchContactFieldById.pending]: (state) => {},
    [fetchContactFieldById.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "success") {
        state.contactField = payload.data;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [fetchContactFieldById.rejected]: (state) => {},

    // Create New Contact Type
    [createContactField.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [createContactField.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contactFields[state.contactFields.length] = payload.data;
        state.isSuccess = true;
      } else {
        state.isFormLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [createContactField.rejected]: (state, { payload }) => {
      state.isFormLoading = false;
      state.isError = true;
      state.message = payload.message;
    },

    // Update Contact Type
    [updateContactField.pending]: (state) => {
      state.formErrors = null;
      state.isFormLoading = true;
    },
    [updateContactField.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
        state.isFormLoading = false;
        state.formErrors = payload.message;
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        let index = state.contactFields.indexOf(
          state.contactFields.find((type) => type._id === payload.data._id)
        );
        state.contactFields = [
          ...state.contactFields.slice(0, index),
          payload.data,
          ...state.contactFields.slice(index + 1),
        ];

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [updateContactField.rejected]: (state) => {},

    // Delete Contact Type
    [deleteContactField.pending]: (state) => {
      state.isFormLoading = true;
    },
    [deleteContactField.fulfilled]: (state, { payload }) => {
      if (!payload) {
        return;
      }
      if (payload.status === "form-error") {
      } else if (payload.status === "success") {
        state.isFormLoading = false;
        state.message = payload.message;
        state.contactFields = state.contactFields.filter(
          (type) => type._id !== payload.data.id
        );

        state.isSuccess = true;
      } else {
        state.isLoading = false;
        state.isError = true;
        state.message = payload.message;
      }
    },
    [deleteContactField.rejected]: (state) => {},
  },
});

export const { reset } = ContactFieldsSlice.actions;

export default ContactFieldsSlice.reducer;

import "./Settings.css";

function Settings() {
  return (
    <div id="settings">
      <h1 className="text-primary">Settings</h1>

      <section>
        <h3>Theme</h3>
        <div>
          <button className="light">Light</button>
          <button className="default">Dark</button>
        </div>
      </section>

      <section>
        <h3>Font</h3>
        <div>
          <button>Roboto</button>
          <button>Poppins</button>
        </div>
      </section>

      <section>
        <h3>Sidebar</h3>
        <div>
          <button>Roboto</button>
          <button>Poppins</button>
        </div>
      </section>
    </div>
  );
}

export default Settings;

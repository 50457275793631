import "./Sidebar.css";
import { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faChartColumn,
  faAddressBook,
  faGears,
  faUsers,
  faUserTag,
  faFileContract,
  faFileSignature,
  faPhone,
  faDollarSign,
  faThumbsUp,
  faBan,
  faHand,
  faChevronRight,
  faBullhorn,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar({
  sidebarOpen,
  sidebarExpanded,
  sidebarRight,
  toggleSidebarExpanded,
  toggleSidebar,
  closeSidebar,
}) {
  const { authUser } = useSelector((store) => store.authentication);

  const sidebar_content = [
    {
      type: "link",
      label: "Dashboard",
      route: "/",
      icon: faChartColumn,
    },
    {
      type: "section",
      title: "Ted User Management",
      link: "/ted-users",
      inside: [
        {
          type: "link",
          label: "Users",
          route: "/ted-users",
          permission: "users",
          icon: faUsers,
        },
        {
          type: "link",
          label: "Roles",
          route: "/ted-roles",
          permission: "roles",
          icon: faUserTag,
        },
      ],
    },
    {
      type: "section",
      title: "Ted Contacts",
      link: "/ted-contacts",
      inside: [
        {
          type: "link",
          label: "Contacts",
          route: "/ted-contacts",
          permission: "contacts",
          icon: faAddressBook,
        },
        {
          type: "link",
          label: "Teasing Contacts",
          route: "/ted-teasing-contacts",
          permission: "teasing-contacts",
          icon: faBullhorn,
        },
        {
          type: "dropdown",
          label: "Contacts Settings",
          icon: faGears,
          inside: [
            {
              type: "link",
              label: "Contact Types",
              permission: "contact-types",
              route: "/ted-contacts/contact-types",
            },
            {
              type: "link",
              label: "Contact Fields",
              permission: "contact-fields",
              route: "/ted-contacts/contact-fields",
            },
          ],
        },
      ],
    },
    {
      type: "section",
      title: "Ted Projects",
      link: "/",
      inside: [
        {
          type: "link",
          label: "Leads",
          route: "/ted-projects/leads",
          permission: "leads",
          icon: faFileContract,
        },
        {
          type: "link",
          label: "Meetings",
          permission: "meetings",
          route: "/ted-projects/meetings",
          icon: faPhone,
        },
        {
          type: "link",
          label: "Proposals",
          permission: "proposals",
          route: "/ted-projects/proposals",
          icon: faFileSignature,
        },
        {
          type: "link",
          label: "Sales",
          permission: "sales",
          route: "/ted-projects/sales",
          icon: faDollarSign,
        },
        {
          type: "link",
          label: "Approved",
          permission: "approved",
          route: "/ted-projects/approved",
          icon: faThumbsUp,
        },
        {
          type: "link",
          label: "Rejected",
          permission: "rejected",
          icon: faBan,
          route: "/ted-projects/rejected",
        },
        {
          type: "link",
          label: "Stopped",
          permission: "stopped",
          route: "/ted-projects/stopped",
          icon: faHand,
        },
      ],
    },
  ];

  const openDropdown = (event) => {
    var dropdown = event.target.closest(".dropdown");
    dropdown.classList.toggle("open");
  };

  return (
    <div
      id="sidebar"
      className={`${sidebarRight ? "right" : ""} ${
        !sidebarOpen ? "closed" : ""
      }`}
    >
      <div
        onMouseLeave={() => {
          if (!sidebarOpen && window.innerWidth > 1200) {
            toggleSidebarExpanded();
          }
        }}
        className={`h-100 ${sidebarExpanded ? "expanded" : ""}`}
      >
        <div>
          {sidebarExpanded && !sidebarOpen && (
            <button
              className="btn btn-primary icon-btn toggle-sidebar-expanded"
              onClick={() => {
                toggleSidebar();
              }}
            >
              <FontAwesomeIcon
                icon={sidebarRight ? faAnglesLeft : faAnglesRight}
              />
            </button>
          )}
          <button
            className="btn btn-primary icon-btn close-sidebar"
            onClick={() => {
              closeSidebar();
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h3 className="text-highlight">Ted Manager</h3>
        </div>
        <div>
          <ul>
            {sidebar_content.map((child, index) => {
              if (
                child.type == "link" &&
                (!child.permission ||
                  authUser?.permissions.includes(child.permission))
              ) {
                return (
                  <li key={index}>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to={child.route}
                    >
                      {child.icon && <FontAwesomeIcon icon={child.icon} />}

                      {child.label}
                    </NavLink>
                  </li>
                );
              } else if (child.type == "section") {
                return (
                  <div className="section dropdown" key={index}>
                    <div className="section-toggle clickable d-flex align-items-center">
                      <Link
                        className="text-primary no-decoration"
                        to={child.link}
                      >
                        <h4>{child.title}</h4>
                      </Link>
                      <h5
                        onClick={(event) => {
                          openDropdown(event);
                        }}
                        className="ml-auto"
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </h5>
                    </div>
                    <div>
                      <div>
                        {child.inside.map((inside, index1) => {
                          if (
                            inside.type == "link" &&
                            (!inside.permission ||
                              authUser?.permissions.includes(inside.permission))
                          ) {
                            return (
                              <li key={index1}>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive ? "active" : ""
                                  }
                                  to={inside.route}
                                >
                                  {inside.icon && (
                                    <FontAwesomeIcon icon={inside.icon} />
                                  )}

                                  {inside.label}
                                </NavLink>
                              </li>
                            );
                          } else if (inside.type == "dropdown") {
                            return (
                              <div className="dropdown" key={index1}>
                                <div className="clickable dropdown-toggle d-flex align-items-center">
                                  {inside.icon && (
                                    <FontAwesomeIcon icon={inside.icon} />
                                  )}{" "}
                                  {inside.label}
                                  <h6
                                    className="ml-auto"
                                    onClick={(event) => {
                                      openDropdown(event);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                  </h6>
                                </div>
                                <div>
                                  <div data-childcount={inside.inside.length}>
                                    {inside.inside.map((inside1, index2) => {
                                      if (
                                        inside1.type == "link" &&
                                        (!inside1.permission ||
                                          authUser?.permissions.includes(
                                            inside1.permission
                                          ))
                                      ) {
                                        return (
                                          <li key={index2}>
                                            <NavLink
                                              className={({ isActive }) =>
                                                isActive ? "active" : ""
                                              }
                                              to={inside1.route}
                                            >
                                              {inside1.icon && (
                                                <FontAwesomeIcon
                                                  icon={inside1.icon}
                                                />
                                              )}

                                              {inside1.label}
                                            </NavLink>
                                          </li>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

{
  /* <NavLink
          className={({ isActive }) => (isActive ? "link active" : "link")}
          to="/settings"
        >
          Settings
        </NavLink> */
}
